import $ from 'jquery'
import _ from 'lodash'

const accentMap = {
  ľ: 'l',
  š: 's',
  č: 'c',
  ť: 't',
  ž: 'z',
  ý: 'y',
  á: 'a',
  í: 'i',
  é: 'e',
  ä: 'a',
  ú: 'u',
  ň: 'n',
  ô: 'o',
}

export function slugify(str) {
  str = str.toLowerCase()
  let s = ''
  for (let i = 0; i < str.length; i++) {
    s += accentMap[str[i]] || str[i]
  }
  str = s

  return str.replace(/[^a-zA-Z0-9\w]/g, '-').replace(/(-)\1+/, '$1')
}

function slugAutogen($slugInput, $sourceInput) {
  let prev = ''
  $sourceInput.keyup((e) => {
    let s = $(e.currentTarget).val()
    s = slugify(s)

    prev = $slugInput.val()
    $slugInput.val(s)
  })
}

$(() => {
  slugAutogen($('#product-slug'), $('#product-name'))
  slugAutogen($('#category-slug'), $('#category-name'))
})
