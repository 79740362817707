import $ from 'jquery'

$(() => {
  $(window).click((e) => {
    $('.dropdown-submenu > ul').removeClass('show')
  })

  $('.dropdown-submenu').click((e) => {
    // when some submenu is selected, hide all others except current tree
    $('.dropdown-submenu').each((_, ds) => {
      if (!$.contains(ds, e.currentTarget)) {
        $(ds).find('> .dropdown-menu').removeClass('show')
      }
    })

    $(e.currentTarget).find('> ul').toggleClass('show')
    e.stopPropagation()
    // e.preventDefault()
  })
})
