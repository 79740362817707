import $ from 'jquery'
import { setupQuill } from './quill'
import Dropzone from 'dropzone'

const logoTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

const bannerImageTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

function renderLogoImage() {
  if (!window.logoImageId) {
    $('#logo-image-container').html(null)
    return
  }

  const templ = $(logoTemplate)
  templ.find('img').attr('src', `/blobs/${window.logoImageId}`)
  templ.find('.image-link').attr('href', `/blobs/${window.logoImageId}`)

  templ.find('i.delete').click(() => {
    if (!confirm('Naozaj zmazať logo ?')) {
      return
    }

    $.post({
      url: `/blobs/${window.logoImageId}/delete`,
    })
      .then(() => {
        window.logoImageId = null
        renderLogoImage()
      })
      .catch((e) => {
        console.error(e)
        alert('Nepodarilo sa zmazať logo')
      })
  })

  $('#logo-image-container').html(templ)
}

function renderBannerImages() {
  $('#banner-image-container').html(
    window.bannerImageIds.map((id) => {
      const templ = $(bannerImageTemplate)
      templ.find('img').attr('src', `/blobs/${id}`)
      templ.find('.image-link').attr('href', `/blobs/${id}`)

      templ.find('i.delete').click(() => {
        if (!confirm('Naozaj zmazať banner ?')) {
          return
        }

        $.post({
          url: `/blobs/${id}/delete`,
        })
          .then(() => {
            window.bannerImageIds = window.bannerImageIds.filter(
              (i) => i !== id
            )
            renderBannerImages()
          })
          .catch((e) => {
            console.error(e)
            alert('Nepodarilo sa zmazať banner')
          })
      })

      return templ
    })
  )
}

export function setupBrandEditPage() {
  renderLogoImage()
  renderBannerImages()

  const logodz = new Dropzone('#logo-upload', {
    url: window.urls.uploadLogoUrl,
    method: 'post',
    maxFiles: 1,
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte logo značky',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.logoImageId = res.id
      renderLogoImage()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať logo (${m})`)
    },
    complete: (f) => {
      logodz.removeFile(f)
    },
  })

  const bannerdz = new Dropzone('#banner-upload', {
    url: window.urls.uploadBannerUrl,
    method: 'post',
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte banner značky',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.bannerImageIds.push(res.id)
      renderBannerImages()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať logo (${m})`)
    },
    complete: (f) => {
      bannerdz.removeFile(f)
    },
  })

  const $brandForm = $('#edit-brand-form')
  setupQuill(
    $('#quill-text'),
    $('#quill-text-destination'),
    $brandForm,
    'Text značky...'
  )
}
