import 'bootstrap'
import Dropzone from 'dropzone'
import $ from 'jquery'
window.jQuery = $
window.$ = $
import '@chenfengyuan/datepicker'
import './lib/jquery.timepicker'
import './lib/bootstrap-multiselect'

import './nested-dropdown'
import './interactive-form'
import './slug'
import { setupPage } from './product-edit'
import { setupProductPage } from './product-page'
import { setupBannerEdit } from './banner-edit'
import { setupHomePage } from './homepage'
import { setupBrandEditPage } from './brand-edit'
import { setupBrandButtonEdit } from './brand-button-edit'
import { setupButtonEdit } from './button-edit'
import { setupDiscountEdit } from './discount-edit'
import { setupJobEdit } from './job-edit'
import { setupEventEditPage } from './event-edit'
import { setupContactPage, gmapsCallback } from './contacts-page'
import { setupQuill } from './quill'
import { showCookieNotice } from './cookies'
import { setupSearch } from './search'

Dropzone.autoDiscover = false

window.initialize = gmapsCallback

window.robinco = {
  productEdit: {
    setupPage,
  },
  productPage: {
    setupProductPage,
  },
  bannerEdit: {
    setupBannerEdit,
  },
  homepage: {
    setupHomePage,
  },
  brandEdit: {
    setupBrandEditPage,
    setupBrandButtonEdit,
  },
  discountEdit: {
    setupDiscountEdit,
  },
  jobEdit: {
    setupJobEdit,
  },
  eventEdit: {
    setupEventEditPage,
  },
  contactPage: {
    setupContactPage,
  },
  textsEdit: {
    setupTextsEditPage: () => {
      const $textForm = $('#edit-text-form')
      setupQuill(
        $('#quill-text'),
        $('#quill-text-destination'),
        $textForm,
        'Text...'
      )
    },
  },
  buttonEdit: {
    setupButtonEdit,
  },
}

$(() => {
  showCookieNotice()
  setupSearch()

  $('[data-toggle="popover"]').popover()

  $('form[data-requires-confirmation]').submit((e) => {
    if (!confirm($(e.currentTarget).data('requires-confirmation'))) {
      e.preventDefault()
    }
  })

  $('[data-toggle="datepicker"]').datepicker({
    autoHide: true,
    format: 'dd.mm.yyyy',
  })

  $('[data-toggle="timepicker"]').each(function () {
    const $this = $(this)
    $this.timepicker({
      timeFormat: 'h:mm p',
      interval: '60',
      defaultTime: $this.val(),
      startTIme: '01:00',
      dynamic: false,
    })
  })

  $('.empty-html-hide').each(function () {
    const $this = $(this)
    const src = $this.find('.empty-html-hide-source')[0]

    if (src && src.innerText && src.innerText.trim() !== '') {
      $this.removeClass('empty-html-hide')
    }
  })

  $('.multiselect').multiselect({
    nonSelectedText: 'Žiadne príbuzné kategórie',
    allSelectedText: 'Všetky',
    buttonClass: 'btn btn-outline-info multiselectbutton',
  })

  $('button[data-disable-on-submit]').each(function () {
    const $btn = $(this)
    $btn.closest('form').submit(function () {
      $btn.attr('disabled', 'disabled')
      $btn.text($btn.data('disable-on-submit'))
    })
  })
})
