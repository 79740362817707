import $ from 'jquery'

export function gmapsCallback() {
  var mapOptions = {
    zoom: 7,
    center: new google.maps.LatLng(48.6, 19.0),
  }
  var map = new google.maps.Map($('#map-canvas')[0], mapOptions)
  var image = {
    url: '/assets/images/RMarker.png',
    size: new google.maps.Size(32, 47),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(16, 47),
  }

  var markerBa = new google.maps.Marker({
    position: new google.maps.LatLng(48.21234, 17.172858),
    map: map,
    title: 'Pobočka Bratislava',
    animation: google.maps.Animation.DROP,
    icon: image,
  })
  var markerZa = new google.maps.Marker({
    position: new google.maps.LatLng(49.2194, 18.7289),
    map: map,
    title: 'Pobočka Žilina',
    animation: google.maps.Animation.DROP,
    icon: image,
  })
}

export function setupContactPage() {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src =
    'https://maps.googleapis.com/maps/api/js?language=sk&v=3&region=SK&key=AIzaSyAbwJchakVy6YDBTkpc_GerMrKCtM1zUuo&callback=initialize'

  document.body.appendChild(script)
}
