import $ from 'jquery'
import { setupQuill } from './quill'

export function setupJobEdit() {
  const $jobForm = $('#edit-jobs-form')

  setupQuill(
    $('#quill-description'),
    $('#quill-description-destination'),
    $jobForm,
    'Popisok pracovnej ponuky...'
  )
}
