﻿import $ from 'jquery'

const debounce = (wait, callback) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}

const debouncedSearch = debounce(1000, function (v) {
    if (!v || v.length < 3) {
        return
    }

    $('.search-modal-query').attr('disabled', true)

    fetch('/katalog/vyhladavanie/' + v, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(r => r.json())
        .then(r => renderSearchResults(r, false))
        .catch(e => console.error('failed to fetch catalog search', e))
        .then(() => $('.search-modal-query').attr('disabled', false).focus())
})

function hydrateItemTemplate(item) {
    const itemNode = $($('#search-modal-result-item-template').html())

    if (item.previewImageId) {
        itemNode.find('.search-modal-result-item-image').attr('src', `/blobs/${item.previewImageId}`) 
    } else {
        itemNode.find('.search-modal-result-item-image').remove()
    }

    if (item.searchResultItemType === 'Product') {
        if (item.itemType === 'Product') {
            itemNode.attr('href', `/katalog/produkt/${item.slug}`)
        }
        if (item.itemType === 'Solution') {
            itemNode.attr('href', `/katalog/riesenie/${item.slug}`) 
        }
    } 
    if (item.searchResultItemType === 'Category') {
        itemNode.attr('href', `/katalog/${item.slug}`)
    }

    itemNode.find('.search-modal-result-item-type')
        .text(item.searchResultItemType === 'Product' ? (item.itemType === 'Product' ? 'Produkt' : 'Riešenie') : 'Kategória')
    itemNode.find('.search-modal-result-item-title').text(item.name)

    return itemNode
}

function renderSearchResults(results, initialRender) {
    $('.search-modal-result').children().remove()

    if (results.length) {
        const renderedItems = results.map(r => hydrateItemTemplate(r))
        $('.search-modal-result').append(renderedItems)
        $('.search-modal-result').scrollTop(0)
    } else if (!initialRender) {
        $('.search-modal-result').append($("<span>Žiadne výsledky</span>"))
    }
}

export function setupSearch() {
    $('.search-modal-backdrop, .search-modal-close-button').on('click', function () {
        $('.search-modal-host').addClass('search-modal-hidden')
        $(document.body).css('overflow', 'auto')
    })

    $('.search-button').on('click', function () {
        $('.search-modal-query').val('')
        renderSearchResults([], true)
        $('.search-modal-host').removeClass('search-modal-hidden')
        $('.search-modal-query').focus()
        $(document.body).css('overflow', 'hidden')
    });

    $('.search-modal-query').on('input', function (e) {
        debouncedSearch(e.currentTarget.value)
    })
}