import $ from 'jquery'
import Dropzone from 'dropzone'

const bannerImageTemplate = `
<div class="col-8 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

function renderBannerImage() {
  if (!window.bannerImageId) {
    $('#banner-image-container').html(null)
    return
  }

  const templ = $(bannerImageTemplate)
  templ.find('img').attr('src', `/blobs/${window.bannerImageId}`)
  templ.find('.image-link').attr('href', `/blobs/${window.bannerImageId}`)

  templ.find('i.delete').click(() => {
    if (!confirm('Naozaj zmazať obrázok ?')) {
      return
    }

    $.post({
      url: `/blobs/${window.bannerImageId}/delete`,
    })
      .then(() => {
        window.bannerImageId = null
        renderBannerImage()
      })
      .catch((e) => {
        console.error(e)
        alert('Nepodarilo sa zmazať obrázok')
      })
  })
  $('#banner-image-container').html(templ)
}

export function setupBannerEdit() {
  renderBannerImage()

  const bannerdz = new Dropzone('#banner-upload', {
    url: window.urls.uploadBannerImage,
    method: 'post',
    maxFiles: 1,
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte obrázok banneru',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.bannerImageId = res.id
      renderBannerImage()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať obrázok (${m})`)
    },
    complete: (f) => {
      bannerdz.removeFile(f)
    },
  })
}
