import $ from 'jquery'
import Dropzone from 'dropzone'
import { setupQuill } from './quill'

import { getKVEditorValues, setupKvEditor, addKv } from './kv-editor'

const previewImageTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

const detailImageTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

const documentTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <i class="fas fa-file-alt placeholder-icon"></i>
    <a class='file-name'></a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

const videoTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <i class="fas fa-file-alt placeholder-icon"></i>
    <a class='file-name'></a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

function renderPreviewImage() {
  if (!window.productPreviewImageId) {
    $('#preview-image-container').html(null)
    return
  }

  const templ = $(previewImageTemplate)
  templ.find('img').attr('src', `/blobs/${window.productPreviewImageId}`)
  templ
    .find('.image-link')
    .attr('href', `/blobs/${window.productPreviewImageId}`)

  templ.find('i.delete').click(() => {
    if (!confirm('Naozaj zmazať obrázok ?')) {
      return
    }

    $.post({
      url: `/blobs/${window.productPreviewImageId}/delete`,
    })
      .then(() => {
        window.productPreviewImageId = null
        renderPreviewImage()
      })
      .catch((e) => {
        console.error(e)
        alert('Nepodarilo sa zmazať obrázok')
      })
  })

  $('#preview-image-container').html(templ)
}

function renderDetailImages() {
  $('#detail-image-container').html(
    window.productDetailImageIds.map((id) => {
      const templ = $(detailImageTemplate)
      templ.find('img').attr('src', `/blobs/${id}`)
      templ.find('.image-link').attr('href', `/blobs/${id}`)

      templ.find('i.delete').click(() => {
        if (!confirm('Naozaj zmazať obrázok ?')) {
          return
        }

        $.post({
          url: `/blobs/${id}/delete`,
        })
          .then(() => {
            window.productDetailImageIds = window.productDetailImageIds.filter(
              (i) => i !== id
            )
            renderDetailImages()
          })
          .catch((e) => {
            console.error(e)
            alert('Nepodarilo sa zmazať obrázok')
          })
      })

      return templ
    })
  )
}

function renderDocuments() {
  $('#document-container').html(
    window.productDocuments.map((doc) => {
      const templ = $(documentTemplate)
      templ.find('.file-name').text(doc.fileName)
      templ.find('.file-name').attr('href', `/blobs/download/${doc.id}`)

      templ.find('i.delete').click(() => {
        if (!confirm('Naozaj zmazať dokument ?')) {
          return
        }

        $.post({
          url: `/blobs/${doc.id}/delete`,
        })
          .then(() => {
            window.productDocuments = window.productDocuments.filter(
              (d) => d.id !== doc.id
            )
            renderDocuments()
          })
          .catch((e) => {
            console.error(e)
            alert('Nepodarilo sa zmazať dokument')
          })
      })

      return templ
    })
  )
}

function renderVideos() {
  $('#video-container').html(
    window.productVideos.map((video) => {
      const templ = $(videoTemplate)
      templ.find('.file-name').text(video.fileName)
      templ.find('.file-name').attr('href', `/blobs/download/${video.id}`)

      templ.find('i.delete').click(() => {
        if (!confirm('Naozaj zmazať video ?')) {
          return
        }

        $.post({
          url: `/blobs/${video.id}/delete`,
        })
          .then(() => {
            window.productVideos = window.productVideos.filter(
              (d) => d.id !== video.id
            )
            renderVideos()
          })
          .catch((e) => {
            console.error(e)
            alert('Nepodarilo sa zmazať video')
          })
      })

      return templ
    })
  )
}

export function setupPage() {
  if (window.productPreviewImageId) {
    renderPreviewImage()
  }
  if (window.productDetailImageIds) {
    renderDetailImages()
  }
  if (window.productDocuments) {
    renderDocuments()
  }

  if (window.productVideos) {
    renderVideos()
  }

  if (!window.urls.productUploadPreview) {
    console.error('No product preview upload url...')
  }
  if (!window.urls.productUploadDetail) {
    console.error('No product detail upload url...')
  }
  if (!window.urls.productUploadDocument) {
    console.error('No product document upload url...')
  }
  if (!window.urls.productUploadVideo) {
    console.error('No product video upload url...')
  }

  const previewdz = new Dropzone('#preview-upload', {
    url: window.urls.productUploadPreview,
    method: 'post',
    maxFiles: 1,
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte obrázok náhľadu produktu',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.productPreviewImageId = res.id
      renderPreviewImage()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať obrázok (${m})`)
    },
    complete: (f) => {
      previewdz.removeFile(f)
    },
  })

  const detaildz = new Dropzone('#detail-upload', {
    url: window.urls.productUploadDetail,
    method: 'post',
    dictDefaultMessage: 'Nahrajte obrázok detailu produktu',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.productDetailImageIds.push(res.id)
      renderDetailImages()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať obrázok (${m})`)
    },
    complete: (f) => {
      detaildz.removeFile(f)
    },
  })

  const documentdz = new Dropzone('#document-upload', {
    url: window.urls.productUploadDocument,
    method: 'post',
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte dokument k produktu',
    timeout: 0,

    success: (f, res) => {
      window.productDocuments.push({
        id: res.id,
        fileName: f?.upload?.filename,
      })
      renderDocuments()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať dokument (${m})`)
    },
    complete: (f) => {
      documentdz.removeFile(f)
    },
  })

  const videodz = new Dropzone('#video-upload', {
    url: window.urls.productUploadVideo,
    method: 'post',
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte video k produktu',
    acceptedFiles: 'video/*',
    timeout: 0,
    success: (f, res) => {
      window.productVideos.push({
        id: res.id,
        fileName: f?.upload?.filename,
      })
      renderVideos()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať video (${m})`)
    },
    complete: (f) => {
      videodz.removeFile(f)
    },
  })

  const $productForm = $('#edit-product-form')
  setupQuill(
    $('#quill-short-description'),
    $('#quill-short-description-destination'),
    $productForm,
    'Krátky popisok produktu...'
  )

  setupQuill(
    $('#quill-long-description'),
    $('#quill-long-description-destination'),
    $productForm,
    'Dlhý popisok produktu...'
  )

  setupQuill(
    $('#quill-properties'),
    $('#quill-properties-destination'),
    $productForm,
    'Vlastnosti...'
  )
}
