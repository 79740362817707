import $ from 'jquery'
import Quill from 'quill'

const recordTempl = `
<div class="row kv-record">
    <div class="col-12">
        <div class="input-group">
            <div class="input-group-prepend">
                <button class="btn btn-danger del-btn">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
            <input type="text" class="key form-control" placeholder="Názov">
        </div>
    </div>
    <div class="col-12">
        <div class="value"></div>
    </div>
</div>`

export function addKv($container, kv) {
  let $templ = $(recordTempl)

  $templ.find('.key').val(kv.key)
  $templ.find('.del-btn').click(() => {
    $templ.remove()
  })
  let $value = $templ.find('.value')
  $value.html(kv.value)

  $container.append($templ)

  new Quill($value[0], {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['link', 'blockquote', 'code-block', 'image'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
    placeholder: 'Špecifikácia',
    theme: 'snow',
  })
}

export function setupKvEditor($input, $container) {
  let vals = []
  try {
    vals = JSON.parse($input.val() || '[]')
  } catch (e) {
    console.warn('KV editor failed to load, defaulting empty', e)
  }

  for (let kvi in vals) {
    let kv = vals[kvi]

    addKv($container, kv)
  }
}

export function getKVEditorValues($container) {
  let buf = []

  $container.find('.kv-record').each((i, record) => {
    let kv = {}
    let $record = $(record)
    kv.key = $record.find('.key').val()
    kv.value = $record.find('.value .ql-editor').html()

    buf.push(kv)
  })

  return buf
}
