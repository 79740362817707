import Quill from 'quill'

export function setupQuill($editorEl, $inputEl, $formEl, placeholder) {
  const quill = new Quill($editorEl[0], {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['link', 'blockquote', 'code-block', 'image'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
    placeholder,
    theme: 'snow',
  })

  $formEl.submit(() => {
    $inputEl.val(quill.root.innerHTML)
  })

  // Any pasted text to editor will be stripped of anyhtml styles to prevent breaking
  $editorEl.on('paste', function (e) {
    // cancel paste
    e.preventDefault()

    // get text representation of clipboard
    var text = (e.originalEvent || e).clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand('insertHTML', false, text)
  })
}
