import $ from 'jquery'
import Dropzone from 'dropzone'

const imageTemplate = `
<div class="col-3 p-1">
  <div class="uploaded-image-container">
    <a class="image-link" target="_blank">
      <img class="uploaded-image" />
    </a>
    <i class="fas fa-trash delete"></i>
  </div>
</div>
`

function renderImage() {
  if (!window.buttonImageId) {
    $('#image-container').html(null)
    return
  }

  const templ = $(imageTemplate)
  templ.find('img').attr('src', `/blobs/${window.buttonImageId}`)
  templ.find('.image-link').attr('href', `/blobs/${window.buttonImageId}`)

  templ.find('i.delete').click(() => {
    if (!confirm('Naozaj zmazať obrázok ?')) {
      return
    }

    $.post({
      url: `/blobs/${window.buttonImageId}/delete`,
    })
      .then(() => {
        window.buttonImageId = null
        renderImage()
      })
      .catch((e) => {
        console.error(e)
        alert('Nepodarilo sa zmazať obrázok')
      })
  })

  $('#image-container').html(templ)
}

export function setupButtonEdit() {
  renderImage()

  const imagedz = new Dropzone('#image-upload', {
    url: window.urls.uploadImageUrl,
    method: 'post',
    maxFiles: 1,
    uploadMultiple: false,
    dictDefaultMessage: 'Nahrajte obrázok tlačidla',
    acceptedFiles: 'image/*',
    timeout: 0,

    success: (f, res) => {
      window.buttonImageId = res.id
      renderImage()
    },
    error: (e, m) => {
      alert(`Nepodarilo sa nahrať obrázok (${m})`)
    },
    complete: (f) => {
      imagedz.removeFile(f)
    },
  })
}
