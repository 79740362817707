import $ from 'jquery'

$(() => {
  function hasInputChanged($input, initFormValues) {
    return initFormValues[$input.attr('name')] !== $input.val()
  }

  $('.interactive-form').each((i, e) => {
    const $form = $(e)
    const $inputs = $form
      .find(`input,textarea`)
      .add($(`*[form="${$form.attr('id')}"]`))

    const initialFormValues = {}
    $inputs.each((_, input) => {
      const $input = $(input)
      initialFormValues[$input.attr('name')] = $input.val()
    })

    $inputs.keyup((e) => {
      const $input = $(e.currentTarget)
      $input.toggleClass(
        'is-changed',
        hasInputChanged($input, initialFormValues)
      )
    })
  })
})
