import $ from 'jquery'

function setupImageShow() {
  $('.image-show img').click((e) => {
    let $modal = $('#image-show-dialog')
    $modal.modal({ show: true })
    $modal.find('img').attr('src', $(e.currentTarget).attr('src'))
  })
}

function setupVideoShow() {
  let $modal = $('#video-show-dialog')
  $modal.on('hidden.bs.modal', function () {
    const video = $modal.find('video')[0]
    if (video && video.pause) {
      video.pause()
    }
  })

  $('.action-video').click((e) => {
    e.preventDefault()

    let $link = $(e.currentTarget)

    $modal.modal({ show: true })

    const $video = $modal.find('video')
    let $source = $video.find('source')
    let shoudReload = false
    if (!$source.length) {
      $video.append($('<source />'))
      $source = $video.find('source')
    } else {
      if ($source.attr('src') !== $link.data('videourl')) {
        shoudReload = true
      }
    }

    $source
      .attr('src', $link.data('videourl'))
      .attr('type', $link.data('videomime'))

    if ($video[0]) {
      if (shoudReload) {
        $video[0].load()
      }
      $video[0].play()
    }
  })
}

export function setupProductPage() {
  $('.carousel').carousel()
  setupImageShow()
  setupVideoShow()
}
