import $ from 'jquery'

export function showCookieNotice() {
    const hasConsent = localStorage.getItem('cookieConsent') !== null
    if (hasConsent) return

    $('.cookie-dialog button').on('click', function(evt) {
        localStorage.setItem('cookieConsent', $(evt.currentTarget).data('consent') || 'yes')

        $('.cookie-dialog').fadeOut(200)
    })

    $('.cookie-dialog').toggleClass('cookie-dialog-hidden')
}