import $ from 'jquery'

class Ticker {
  constructor(news, $target) {
    this.news = news
    this.$target = $target
    this.currentNewsIndex = 0
    this.currentCharIndex = 0
    this.pendingElapsed = 0
    this.state = 'drawing'
    this.delay = 35

    this.handle = null
  }

  start() {
    this.stop()

    this.handle = setInterval(() => {
      this.step(this.delay)
    }, this.delay)
  }

  stop() {
    if (this.handle) {
      clearInterval(this.handle)
      this.handle = null
    }
  }

  freeze() {
    let n = this.news[this.currentNewsIndex]
    this.stop()
    this.currentCharIndex = n.text.length
    this.pendingElapsed = 0
    this.state = 'frozen'
    this.render()
  }

  unfreeze() {
    this.pendingElapsed = 0
    this.state = 'pending'
    this.start()
  }

  next() {
    this.currentNewsIndex = (this.currentNewsIndex + 1) % this.news.length
    this.currentCharIndex = 0
    this.pendingElapsed = 0
    this.state = 'drawing'
  }

  step(delta) {
    let n = this.news[this.currentNewsIndex]

    switch (this.state) {
      case 'drawing':
        this.currentCharIndex = Math.min(
          this.currentCharIndex + 1,
          n.text.length
        )
        break

      case 'pending':
        this.currentCharIndex = n.text.length
        this.pendingElapsed += delta
        break
      case 'frozen':
        break

      default:
        throw new Error('invalid state')
    }
    this.render()

    if (this.currentCharIndex === n.text.length) {
      this.state = 'pending'
    }

    if (this.pendingElapsed > 3000) {
      this.next()
    }
  }

  render() {
    let n = this.news[this.currentNewsIndex]
    let templ = $('<a />')
    let text = n.text || ''

    templ.attr('href', n.link)
    templ.text(text.slice(0, Math.abs(this.currentCharIndex)))

    this.$target.html(templ)
  }
}

export function setupHomePage(news) {
  if (news && news.length) {
    const $news = $('.rolling-news')
    let t = new Ticker(news, $news.find('.text'))
    t.start()

    $news.hover(
      () => {
        t.freeze()
      },
      () => {
        t.unfreeze()
      }
    )

    $news.find('.next').click(() => {
      t.next()
      t.freeze()
    })
  }
}
